<template>
    <div>
        <h3 class="my-2">Public Records</h3>
        <v-card flat>
            <v-card-text>
                <psi-simple-table
                    :items="publicRecords"
                    :headers="headers"
                ></psi-simple-table>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
export default {
    name: "credit-public-records",
    components: {},
    props: {
        publicRecords: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        headers() {
            return this.publicRecords.length === 0
                ? []
                : Object.keys(this.publicRecords[0]).map((key) => {
                      return {
                          title: this.knownTitles(key),
                          class: "text-left",
                          key,
                      };
                  });
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        knownTitles(key) {
            switch (key) {
                case "assets":
                    return "Assets";
                case "datePaid":
                    return "Date Paid";
                case "dateReported":
                    return "Date Reported";
                case "publicRecordType":
                    return "Public Record Type";
                case "liabilitiesAmount":
                    return "Liabilities Amount";
                default:
                    return key;
            }
        },
    },
};
</script>

<style scoped>
</style>